<!-- 导航 -->
<template>
    <div class="page-container">
        <el-container class="skeleton-container">
            <el-header class="page-header">
                <h3 class="title">
                    MetaPro 2.0: A Computational Metaphor Processing System
                </h3>
            </el-header>
            <el-main class="page-main">
                <router-view/>
            </el-main>
            <el-footer class="page-foot" height="20px">
                <div class="left">
                    Contact us: <a href="mailto:metapro@ruimao.tech">metapro@ruimao.tech</a>
                </div>
                <div class="right">
                    Search Chinese poems:
                    <a href="https://www.wensousou.com" target="_blank">www.wensousou.com</a>
                </div>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
import { Container, Header, Main } from 'element-ui';

export default {
    data () {
        return {
        };
    },

    components: {
        ElContainer: Container,
        ElHeader: Header,
        ElMain: Main,
    },

    computed: {

    },

    created() {},

    methods: {

    },
};

</script>

<style lang="less" scoped>
.page-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    .page-header {
        height: 48px;
        display: flex;
        align-items: center;
        .title {
            font-size: 24px;
        }
    }
    .page-main {
        flex: 1;
        overflow: scroll;
    }
    .page-foot {
        display: flex;
        justify-content: space-between;
        height: auto !important;
        padding: 20px 40px;
        box-sizing: border-box;
        background: #f5f7fa;
        a {
            color: #409eff;
        }
    }
}
</style>